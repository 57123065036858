
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import components from './row'
  import { Row, RowComponent } from './row/interfaces'

  import { Detail } from '@/entities/public/Resource/interfaces/form'
  import { Query } from '@/utils/computed/Query'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import { buildCondition } from '@/utils/conditional'

interface ListItem {
  component: RowComponent;
  data: {
    props: Row;
    on: Record<string, Function>;
  };
}

@Component({ components })
  export default class RowList extends Vue {
  @Prop({ type: Object, required: true }) content!: any;
  @Prop({ type: Array, required: true }) rows!: Array<Detail>;

  @Prop({ type: Function, required: true }) fetch!: Function;

  assignParent (child, parent, value) {
    if (!child || !parent) return

    const name = typeof parent === 'string' ? parent : parent.name
    if (name in child && child[name]) return

    delete child[name]
    const path = typeof parent !== 'string' ? parent.path : '@'
    Object.defineProperty(child, name, {
      get () { return getObjectValueByPath(value, path) },
    })
  }

  conditions (row: Detail, value: any) {
    const { computed } = row
    if (!computed) return

    const { conditions } = computed
    if (!conditions) return

    const props = {}

    Object.entries(conditions).forEach(([prop, statement]) => {
      const condition = buildCondition(value, statement)
      Object.defineProperty(props, prop, {
        get () {
          return condition.result
        },
        enumerable: true,
      })
    })

    return props
  }

  extracted (row: Detail, value: any) {
    const { computed } = row
    if (!computed) return

    const { extracted } = computed
    if (!extracted) return

    const props = {}

    Object.entries(extracted).forEach(([prop, path]) =>
      Object.defineProperty(props, prop, {
        get () {
          return getObjectValueByPath(value, path)
        },
        enumerable: true,
      })
    )

    return props
  }

  queries (row: Detail, value: any): Record<string, Promise<any>> {
    const { computed } = row
    if (!computed) return

    const { queries } = computed
    if (!queries) return

    const { fetch } = this
    const props = {}

    Object.entries(queries).forEach(([prop, definition]) => {
      const query = new Query(definition, value, fetch)
      Object.defineProperty(props, prop, {
        get () {
          const cache = query.cache
          if (cache) return cache

          return query.value()
        },
        enumerable: true,
      })
    })

    return props
  }

  get items (): Array<ListItem> {
    const { rows, content } = this

    return rows.map(row => {
      const { properties: props, component, scope = 'target', target, parent } = row
      const value = getObjectValueByPath(content, scope === 'target' ? target : '@')
      if (!value) return undefined

      this.assignParent(value, parent, content)
      const conditions = this.conditions(row, value)
      const extracted = this.extracted(row, value)
      const queries = this.queries(row, value)

      return {
        component,
        data: {
          props: {
            ...props,
            ...conditions,
            ...extracted,
            queries,
            value,
          },
        },
      } as ListItem
    }).filter(Boolean)
  }

  render (h) {
    const { items } = this
    if (!items?.length) return

    const visible = items.filter(({ data: { props: { hidden } } }) => !hidden)

    return h(
      'div',
      { class: { list: true } },
      visible.map(({ component, data }) => h(component, data))
    )
  }
  }
