
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { Auto } from '@/entities/public'
  import Panels from '../panels'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { mapActions } from 'vuex'

@Component({
  components: {
    ...Panels,
  },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class AutoContent extends Vue {
  @Prop({ type: Object, required: true }) auto!: Auto;
  @Prop({ type: Boolean, default: true }) detailed?: boolean;

  alternativeAttributes = []

  color = null

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  get attributes () {
    const { auto } = this

    this.alternativeAttributes = []

    this.color = auto?.dealAutoAttribute?.find(component => component.slug === 'color')
    if (!auto.generation?.attributes?.length) {
      this.findAlternativesAttributes(auto)
    }
    return auto?.generation?.attributes
  }

  get favorites () {
    const { attributes, alternativeAttributes } = this

    if (alternativeAttributes?.length) {
      const favoritesAlternative = alternativeAttributes.filter(({ base: { favorite } }) => favorite) || []
      if (!favoritesAlternative.length) return []
      favoritesAlternative.push(this.color)
      return favoritesAlternative.filter(Boolean)
    }

    const favorites = attributes?.filter(({ base: { favorite } }) => favorite) || []
    if (!favorites.length) return []
    favorites.push(this.color)
    return favorites?.filter(Boolean)
  }

  get groups () {
    const { detailed } = this
    if (!detailed) return undefined

    const { attributes, alternativeAttributes } = this
    const attrs = attributes?.length ? attributes : alternativeAttributes
    const groups = this.getGroupsFromAttributes(attrs)

    return Object.entries(groups).map(([title, { icon, attributes }]) => ({
      icon,
      title,
      attributes,
    }))
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      this.alternativeAttributes = generation?.attributes
      return
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      this.alternativeAttributes = attributes
      return
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        this.alternativeAttributes = generation?.attributes || []
        break
      } else {
        cont++
      }
    }

    this.alternativeAttributes = generation?.attributes || []
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
    })
  }

  getGroupsFromAttributes (attributes): Record<string, { icon: string, attributes }> {
    const groups: Record<string, { icon: string, attributes }> = {}
    attributes?.filter(({ base }) => base.multiple).forEach(attr => {
      const { base: { name, icon } } = attr
      if (!groups[name]) {
        groups[name] = { icon, attributes: [attr] }
        return
      }
      groups[name].attributes.push(attr)
    })

    for (const groupName in groups) {
      groups[groupName].attributes.sort((a, b) => a.val.toLowerCase().localeCompare(b.val.toLowerCase()))
    }

    return groups
  }
  }
